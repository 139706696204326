<template>
  <div class="">
    <div class="title">
      <img src="../../assets/images/logbgimg.jpg" alt="" class="back" />
      <h4>地点打卡</h4>`
</div>
<div id="allmap"></div>
<div class="circle " onclick="punch()">
      <div class="time"></div>
      <div>地点打卡</div>
</div>
  </div>
</template>

<script>

export default {
 
  
};
</script>

<style lang="scss" scoped>

 .title {
      height: 44px;
      display: flex;
      align-items: center;
      font-size: 17px;
      color: #030303;
    }

    .back {
      width: 12px;
      height: 21px;
      margin-left: 10px;
    }

    h4 {
      flex: 1;
      text-align: center;
    }

    #allmap {
      width: 100%;
      height: 400px;
    }

    .circle {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #fafafa;
      text-align: center;
      margin: 80px auto;
      font-size: 12px;
      box-shadow: 0 0 0 15px rgba(48, 52, 207, 0.5),
        0 0 0 30px rgba(48, 52, 207, 0.3),
        0 0 0 45px rgba(48, 52, 207, 0.1);
    }

    .time {
      padding-top: 20px;
      font-size: 14px;
    }
</style>
